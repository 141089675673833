/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Route } from '../../../router/routes';
import {
  buttonConnect,
  buttonDocs,
  buttonsContainer,
  links,
  root,
} from './header.css';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { ReactComponent as Logout } from '../../../assets/icons/logout.svg';
import { Box } from '../box';
import { Button } from '../button';
import { ReactComponent as Wallet } from '../../../assets/icons/wallet.svg';
import { shortenAddress } from '../../../utils/shorten-address';
import { ModalConnectWallet } from '../../../components/modals/connect-wallet';
import { theme } from '../../../themes/main.css';
import { AuthStore, SignerStore } from '../../../store/types';

export const Header: FC<{
  auth?: AuthStore['auth'];
  setIsModalConnectWallet: (value: boolean) => void;
  isModalConnectWallet: boolean;
  setAuth: AuthStore['setAuth'];
  signer?: SignerStore['signer'];
  setSigner: SignerStore['setSigner'];
}> = ({
  auth,
  isModalConnectWallet,
  setIsModalConnectWallet,
  setAuth,
  signer,
  setSigner,
}) => (
  <header className={root}>
    <Box display="grid" justifyContent="space-between" alignItems="center">
      <Link to={Route.home.path}>
        <Logo />
      </Link>
      <nav>
        <Box display="grid" space="xxl" alignItems="center">
          <span className={links}>
            <Box display="grid" space="xxl" alignItems="center">
              <a
                style={{ color: theme.color['gray-4'], cursor: 'not-allowed' }}
                href="#"
              >
                Governance
              </a>
              <a
                href="https://stakebooster.gitbook.io/untitled/"
                target="_blank"
                rel="noreferrer"
              >
                Documentation
              </a>
            </Box>
          </span>
          <div className={buttonsContainer}>
            <Button
              className={buttonDocs}
              buttonSize="small"
              buttonType="secondary"
              onClick={() =>
                window.open(
                  'https://stakebooster.gitbook.io/untitled/',
                  '_blank'
                )
              }
            >
              Docs
            </Button>
            {auth ? (
              <Box display="grid" space="xs">
                <Wallet />
                {shortenAddress(auth.address)}
                <Logout
                  cursor="pointer"
                  onClick={() => {
                    if (auth.type === 'mobile' || auth.type === 'web') {
                      signer?.logout();
                      setSigner(undefined);
                    }

                    setAuth(undefined);
                  }}
                />
              </Box>
            ) : (
              <Button
                className={buttonConnect}
                buttonSize="small"
                onClick={() => setIsModalConnectWallet(true)}
              >
                {''}
              </Button>
            )}
          </div>
        </Box>
      </nav>
    </Box>
    <ModalConnectWallet
      isOpen={isModalConnectWallet}
      onClose={() => setIsModalConnectWallet(false)}
    />
  </header>
);
