import { FC, useEffect } from 'react';
import { ModalBase } from '../base/modal-base';
import { ModalProps } from '../base/modal-base.types';
import {
  activeButton,
  activeWallet,
  content,
} from './modal-connect-wallet.css';
import { Box } from '../../primitives/box';
import { ReactComponent as Keeper } from '../../../assets/icons/keeper.svg';
import { useAuth } from '../../../store/store';
import { ReactComponent as WavesExchange } from '../../../assets/icons/waves-exchange.svg';
import { useConnectWallet } from '../../../hooks/use-connect-wallet';

export const ModalConnectWallet: FC<ModalProps> = (props) => {
  const auth = useAuth(({ auth }) => auth);
  const connectWallet = useConnectWallet();

  useEffect(() => {
    if (auth) props.onClose();
  }, [props, auth]);

  return (
    <ModalBase {...props} title="Connect wallet">
      <div className={content}>
        <Box
          display="grid"
          space="md"
          alignItems="center"
          justifyContent="stretch"
          className={activeWallet}
        >
          <Box
            display="grid"
            space="md"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => connectWallet('desktop')}
          >
            <Box display="grid" space="md" alignItems="center">
              <Keeper />
              <span>
                <Box display="grid" justifyContent="space-between">
                  <b>Keeper Desktop</b>
                </Box>
              </span>
            </Box>
            <button
              onClick={(e) => {
                e.stopPropagation();
                connectWallet('desktop');
              }}
              className={activeButton}
            >
              Choose
            </button>
          </Box>
        </Box>
        <Box
          display="grid"
          alignItems="center"
          justifyContent="space-between"
          className={activeWallet}
        >
          <Box
            display="grid"
            space="md"
            alignItems="center"
            onClick={() => connectWallet('mobile')}
          >
            <Keeper />
            <span>
              <Box display="grid" justifyContent="space-between">
                <b>Keeper Mobile</b>
              </Box>
            </span>
          </Box>
          <button
            onClick={(e) => {
              e.stopPropagation();
              connectWallet('mobile');
            }}
            className={activeButton}
          >
            Choose
          </button>
        </Box>
        <Box
          display="grid"
          alignItems="center"
          justifyContent="space-between"
          className={activeWallet}
        >
          <Box
            display="grid"
            space="md"
            alignItems="center"
            onClick={() => connectWallet('web')}
          >
            <WavesExchange height={29} width={22} />
            <span>
              <Box display="grid" justifyContent="space-between">
                <b>WX.Network</b>
              </Box>
            </span>
          </Box>
          <button
            onClick={(e) => {
              e.stopPropagation();
              connectWallet('web');
            }}
            className={activeButton}
          >
            Choose
          </button>
        </Box>
        <Box
          display="grid"
          alignItems="center"
          justifyContent="space-between"
          className={activeWallet}
        >
          <Box
            display="grid"
            space="md"
            alignItems="center"
            onClick={() => connectWallet('cloud')}
          >
            <WavesExchange height={29} width={22} />
            <span>
              <Box display="grid" justifyContent="space-between">
                <b>WX.Network Email</b>
              </Box>
            </span>
          </Box>
          <button
            onClick={(e) => {
              e.stopPropagation();
              connectWallet('cloud');
            }}
            className={activeButton}
          >
            Choose
          </button>
        </Box>
      </div>
    </ModalBase>
  );
};
