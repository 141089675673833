import { FC } from 'react';
import { Box } from '../box';
import { links, notifierAboutBeta, rights, root } from './footer.css';

export const Footer: FC = () => {
  return (
    <footer className={root}>
      <Box display="grid" space="lg" className={links}>
        <a
          target="_blank"
          href="https://stakebooster.gitbook.io/untitled/"
          rel="noreferrer"
        >
          Docs
        </a>
        <a
          href="https://github.com/StakeBooster"
          target="_blank"
          rel="noreferrer"
        >
          GitHub
        </a>
        <a
          href="https://twitter.com/stakebooster"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        <a href="https://t.me/StakeBooster" target="_blank" rel="noreferrer">
          Telegram
        </a>
      </Box>
      <p className={notifierAboutBeta}>
        Please note that our dApp is currently in beta version. You should
        exercise caution and understand the associated risks before using the
        platform.
      </p>
      <p className={rights}>© {new Date().getFullYear()} StakeBooster</p>
    </footer>
  );
};
