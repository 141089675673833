import { Wallet } from '../store/types';

const isNotKeeperTypes = ['mobile', 'web', 'cloud'] as const;

export const getAuthType = {
  isNotKeeper: (authType?: Wallet) =>
    Boolean(
      authType && authType !== 'desktop' && isNotKeeperTypes.includes(authType)
    ),
  isKeeper: (authType?: Wallet) => authType === 'desktop',
};
