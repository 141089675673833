import { FC, memo } from 'react';
import { Box } from '../box';
import { Button } from '../button';
import { root } from './notifier-about-beta.css';

type Props = {
  onConfirm: () => void;
};

export const NotifierAboutBeta: FC<Props> = memo(({ onConfirm }) => (
  <div className={root}>
    <Box direction="vertical" justifyItems="left" display="grid" space="md">
      <p>
        Please note that our dApp is currently in beta version. You should
        exercise caution and understand the associated risks before using the
        platform.
      </p>
      <Button onClick={onConfirm}>Ok, got it!</Button>
    </Box>
  </div>
));
