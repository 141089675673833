import { create } from 'zustand';
import type {
  UserStore,
  InfoStore,
  NotifierStore,
  LoadingStore,
  LoadingStoreField,
  AuthStore,
  SignerStore,
  Balance,
} from './types';
import { persist } from 'zustand/middleware';

export const useUser = create<UserStore>((set, get) => ({
  totalSurfStaked: 0,
  totalSurfPendingForWithdrawal: 0,
  canWithdraw: false,
  totalClaimedInUSD: 0,
  totalUnclaimedInUSD: 0,
  totalUnclaimedByAsset: [],
  updateUserInfo: async (wallet: string) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/info/${wallet}`);
    const userInfo = await res.json();

    set({ ...userInfo });
  },
  isClaimNotAllowed: () =>
    (get().totalSurfPendingForWithdrawal > 0 && get().canWithdraw === false) ||
    get().totalSurfPendingForWithdrawal === 0,

  sbtPoolLockAmount: 0,
  sbtPoolUnclaimedWx: 0,
  sbtPoolClaimedWx: 0,
  availableToUnlockAmount: 0,
  pools: [],
  balances: [],
  updateBalances: async (wallet: string) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_WAVES_NODE_URL}/assets/balance/${wallet}`
      );

      const balances = (await res.json()).balances as Balance[];

      set({ balances });
    } catch (error) {
      console.error(error);
    }
  },
}));

export const useAuth = create(
  persist<AuthStore>(
    (set) => ({
      auth: undefined,
      setAuth: (auth) => set({ auth }),
    }),
    { name: 'auth' }
  )
);

export const useSigner = create<SignerStore>((set) => ({
  signer: undefined,
  setSigner: (signer) => set({ signer }),
}));

export const useInfo = create<InfoStore>((set) => ({
  tvl: 0,
  totalSurfStaked: 0,
  isSwapActive: false,
  apy: 0,
  totalEarned: 0,
  totalSurfWithdrawalRevenue: 0,
  gNsbt: 0,
  mainContractAddress: '',
  sbtPoolAddress: '',
  updateInfo: async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/info`);
    const info = await res.json();

    set({ ...info });
  },
  pools: [],
  totalgWx: 0,
  totalWxLocked: 0,
}));

export const useNotifier = create(
  persist<NotifierStore>(
    (set) => ({
      confirmed: false,
      setConfirmed: (confirmed: boolean) => set({ confirmed }),
    }),
    { name: 'notifier' }
  )
);

export const useLoadingState = create<LoadingStore>((set) => ({
  isWaitingForTx: false,
  setLoading: (field: LoadingStoreField, value: boolean) =>
    set({ [field]: value }),
}));
