import { FC, useEffect, useState } from 'react';
import { Convert as PConvert } from '../../primitives/convert/convert';
import { waitForTx } from '@waves/waves-transactions';
import { unitParser } from '../../../utils/unit-parser';
import { parseTx } from '../../../utils/parse-tx';
import { useAuth, useInfo, useSigner, useUser } from '../../../store/store';
import { getAuthType } from '../../../utils/get-auth-type';

export const Convert: FC = () => {
  const [wxAmount, setWxAmount] = useState<'' | number>('');
  const [sbWXAmount, setSbWXAmount] = useState<'' | number>('');
  const [loading, setLoading] = useState(false);
  const { mainContractAddress, updateInfo } = useInfo((state) => state);
  const { updateUserInfo, balances } = useUser((state) => state);
  const auth = useAuth(({ auth }) => auth);
  const signer = useSigner(({ signer }) => signer);

  const handleSetMaxAmount = async () => {
    const wxTokenBalance = balances.find(
      ({ assetId }) => assetId === process.env.REACT_APP_WX_ASSET_ID
    )?.balance;

    setWxAmount(Number(unitParser.from(wxTokenBalance ?? 0, 8)));
  };

  const handleConvert = async () => {
    if (wxAmount !== '' && wxAmount > 0) {
      try {
        setLoading(true);

        const txData = {
          type: 16 as const,
          data: {
            dApp: mainContractAddress,
            call: {
              function: 'mintSbWx',
              args: [],
            },
            payment: [
              {
                assetId: process.env.REACT_APP_WX_ASSET_ID as string,
                amount: Math.trunc(unitParser.to(wxAmount, 8)),
              },
            ],
          },
        };

        let txId = '';

        if (getAuthType.isKeeper(auth?.type)) {
          const tx = await KeeperWallet.signAndPublishTransaction(txData);
          txId = parseTx(tx).id;
        }

        if (getAuthType.isNotKeeper(auth?.type)) {
          const transaction = (await signer
            ?.invoke(txData.data)
            .broadcast()) ?? { id: '' };

          txId = (transaction as { id: string }).id ?? '';
        }

        await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });

        setWxAmount('');
        updateInfo();
        auth && updateUserInfo(auth.address);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setSbWXAmount(wxAmount === '' ? '' : wxAmount);
  }, [wxAmount]);

  useEffect(() => {
    setWxAmount(sbWXAmount === '' ? '' : sbWXAmount);
  }, [sbWXAmount]);

  return (
    <PConvert
      convertDisabled={loading}
      onConvert={handleConvert}
      wxAmount={wxAmount}
      setWxAmount={setWxAmount}
      sbWXAmount={sbWXAmount}
      setSbWXAmount={setSbWXAmount}
      onSetMaxAmount={handleSetMaxAmount}
    />
  );
};
