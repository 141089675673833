import { FC, useState } from 'react';
import { ModalBase } from '../base/modal-base';
import { ModalProps } from '../base/modal-base.types';
import { Pool } from '../../../store/types';
import { roundOff } from '../../../utils/round-off';
import {
  controls,
  description,
  headerRow,
  hintTd,
  link,
  primaryTile,
  radios,
  table,
  tableTokensInfo,
  textCell,
} from './staking.css';
import { useUser } from '../../../store';
import { unitParser } from '../../../utils/unit-parser';
import { useAuth, useInfo, useSigner } from '../../../store/store';
import { Box } from '../../primitives/box';
import { Radio } from '../../primitives/radio/radio';
import { Input } from '../../primitives/input/input';
import { Button } from '../../primitives/button';
import { waitForTx } from '@waves/waves-transactions';
import { parseTx } from '../../../utils/parse-tx';
import { ReactComponent as WavesExchange } from '../../../assets/icons/waves-exchange.svg';
import { ReactComponent as Sbt } from '../../../assets/icons/sbt.svg';
import { getAuthType } from '../../../utils/get-auth-type';

export const ModalStaking: FC<ModalProps & { pool?: Pool }> = ({
  pool,
  ...props
}) => {
  const userPools = useUser(({ pools }) => pools);
  const mainContractAddress = useInfo(
    ({ mainContractAddress }) => mainContractAddress
  );
  const [amount, setAmount] = useState<'' | number>('');
  const [action, setAction] = useState<'Stake' | 'Unstake'>('Stake');
  const [actionDisabled, setActionDisabled] = useState(false);
  const [claimDisabled, setClaimDisabled] = useState(false);
  const updateInfo = useInfo(({ updateInfo }) => updateInfo);
  const { updateUserInfo, ...userInfo } = useUser((state) => state);
  const auth = useAuth(({ auth }) => auth);
  const signer = useSigner(({ signer }) => signer);

  const handleExecuteAction = async (pool: Pool) => {
    if (action === 'Stake' && amount !== '' && amount > 0) {
      try {
        setActionDisabled(true);

        const txData = {
          type: 16 as const,
          data: {
            dApp: pool.address,
            call: {
              function: 'deposit',
              args: [],
            },
            payment: [
              {
                assetId: pool.token,
                amount: Math.trunc(unitParser.to(amount, 8)),
              },
            ],
          },
        };

        let txId = '';

        if (getAuthType.isKeeper(auth?.type)) {
          const tx = await KeeperWallet.signAndPublishTransaction(txData);
          txId = parseTx(tx).id;
        }

        if (getAuthType.isNotKeeper(auth?.type)) {
          const transaction = (await signer
            ?.invoke(txData.data)
            .broadcast()) ?? { id: '' };

          txId = (transaction as { id: string }).id ?? '';
        }

        await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });

        updateInfo();
        auth && updateUserInfo(auth.address);
      } catch (e) {
        console.error(e);
      } finally {
        setActionDisabled(false);
      }
    }

    if (action === 'Unstake' && amount !== '' && amount > 0) {
      try {
        setActionDisabled(true);

        const txData = {
          type: 16 as const,
          data: {
            dApp: pool.address,
            call: {
              function: 'withdraw',
              args: [
                {
                  type: 'integer' as const,
                  value: Math.trunc(unitParser.to(amount, 8)),
                },
              ],
            },
            payment: [],
          },
        };

        let txId = '';

        if (getAuthType.isKeeper(auth?.type)) {
          const tx = await KeeperWallet.signAndPublishTransaction(txData);
          txId = parseTx(tx).id;
        }

        if (getAuthType.isNotKeeper(auth?.type)) {
          const transaction = (await signer
            ?.invoke(txData.data)
            .broadcast()) ?? { id: '' };

          txId = (transaction as { id: string }).id ?? '';
        }

        await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });

        updateInfo();
        auth && updateUserInfo(auth.address);
      } catch (e) {
        console.error(e);
      } finally {
        setActionDisabled(false);
      }
    }
  };

  const handleClaim = async (pool: Pool) => {
    try {
      setClaimDisabled(true);

      const txData = {
        type: 16 as const,
        data: {
          dApp: pool.address,
          call: {
            function: 'claim',
            args: [],
          },
          payment: [],
        },
      };

      let txId = '';

      if (getAuthType.isKeeper(auth?.type)) {
        const tx = await KeeperWallet.signAndPublishTransaction(txData);
        txId = parseTx(tx).id;
      }

      if (getAuthType.isNotKeeper(auth?.type)) {
        const transaction = (await signer?.invoke(txData.data).broadcast()) ?? {
          id: '',
        };

        txId = (transaction as { id: string }).id ?? '';
      }

      await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });

      updateInfo();
      auth && updateUserInfo(auth.address);
    } catch (e) {
      console.error(e);
    } finally {
      setClaimDisabled(false);
    }
  };

  const handleSetMaxAmount = async () => {
    if (action === 'Unstake') {
      return setAmount(
        Number(
          unitParser.from(
            userPools.find(({ poolAddress }) => poolAddress === pool?.address)
              ?.userLockedAmountLP ?? 0,
            8
          )
        )
      );
    }

    if (action === 'Stake') {
      const poolTokenBalance = userInfo.balances.find(
        ({ assetId }) => assetId === pool?.token
      )?.balance;

      setAmount(Number(unitParser.from(poolTokenBalance ?? 0, 8)));
    }
  };

  return (
    <ModalBase {...props} title="Staking">
      <>
        <div className={headerRow}>
          <p>{pool?.name}</p>
          <p className={primaryTile}>
            {pool?.apr && roundOff(pool.apr).toLocaleString('en-US')}%
          </p>
          <p className={primaryTile}>
            {Number(pool?.tvl.toFixed(2)).toLocaleString('en-US')}$
          </p>
        </div>
        <p className={description}>
          Deposit liquidity into the {pool?.name} pool (without staking), and
          then stake your LP tokens here to earn boosted WX rewards
        </p>
        <table className={table}>
          <tbody>
            <tr>
              <td className={hintTd}>Your LP Staked</td>
              <td>
                {Number(
                  unitParser.from(
                    userPools.find(
                      ({ poolAddress }) => poolAddress === pool?.address
                    )?.userLockedAmountLP ?? 0,
                    8
                  )
                ).toLocaleString('en-US')}
              </td>
            </tr>
            <tr>
              <td className={hintTd}>Total LP Staked</td>
              <td>
                {pool?.amount ?? 0 > 100
                  ? Number(
                      unitParser.from(pool?.amount ?? 0, 8)
                    ).toLocaleString('en-US')
                  : 0}
              </td>
            </tr>
            <tr>
              <td className={hintTd}>Total earning</td>
              <td>
                {roundOff(pool?.totalEarned ?? 0).toLocaleString('en-US')}$
              </td>
            </tr>
            <tr>
              <td className={hintTd}>Main contract</td>
              <td>
                <a
                  className={link}
                  href={`https://wavesexplorer.com/en/addresses/${mainContractAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td className={hintTd}>Pool contract</td>
              <td>
                <a
                  className={link}
                  href={`https://wavesexplorer.com/en/addresses/${pool?.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <table className={table}>
          <tbody>
            <tr>
              <td className={hintTd}>Claimed</td>
              <td>
                {roundOff(
                  userPools.find(
                    ({ poolAddress }) => poolAddress === pool?.address
                  )?.userClaimedUSD ?? 0
                ).toLocaleString('en-US')}
              </td>
            </tr>
            <tr>
              <td className={hintTd}>Unclaimed</td>
              <td>
                {roundOff(
                  userPools.find(
                    ({ poolAddress }) => poolAddress === pool?.address
                  )?.userUnclaimedUSD ?? 0
                ).toLocaleString('en-US')}
              </td>
            </tr>
            <tr>
              <td className={hintTd}>My deposits</td>
              <td>
                {roundOff(
                  userPools.find(
                    ({ poolAddress }) => poolAddress === pool?.address
                  )?.userLockedAmountUSD ?? 0
                ).toLocaleString('en-US')}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={controls}>
          <Box
            display="grid"
            direction="vertical"
            space="md"
            justifyContent="stretch"
          >
            <div className={radios}>
              <Radio
                id="modal-stake"
                checked={action === 'Stake'}
                onChange={(e) => setAction(e.target.value as 'Stake')}
                value="Stake"
                name="action-modal"
              />
              <Radio
                id="modal-unstake"
                checked={action === 'Unstake'}
                onChange={(e) => setAction(e.target.value as 'Unstake')}
                value="Unstake"
                name="action-modal"
              />
            </div>
            <Input
              value={amount}
              onChange={(e) =>
                setAmount(e.target.value === '' ? '' : Number(e.target.value))
              }
              type="number"
              suffix={
                <Button
                  onClick={handleSetMaxAmount}
                  buttonType="secondary"
                  buttonSize="small"
                >
                  MAX
                </Button>
              }
            />
          </Box>
          <Box display="grid" direction="vertical" justifyContent="stretch">
            <Button
              disabled={actionDisabled}
              onClick={() => pool && handleExecuteAction(pool)}
            >
              {action}
            </Button>
          </Box>
          <table className={tableTokensInfo}>
            <tbody>
              <tr>
                <td className={textCell}>
                  <Box display="grid" alignItems="center">
                    <WavesExchange />
                    WX
                  </Box>
                </td>
                <td>
                  {(userPools.find(
                    ({ poolAddress }) => poolAddress === pool?.address
                  )?.userUnclaimedWx as number) > 100
                    ? Number(
                        unitParser.from(
                          userPools.find(
                            ({ poolAddress }) => poolAddress === pool?.address
                          )?.userUnclaimedWx as number,
                          8
                        )
                      ).toLocaleString('en-US')
                    : 0}
                </td>
                <td className={textCell}>WX APR</td>
                <td>
                  {pool?.aprWx
                    ? roundOff(pool.aprWx).toLocaleString('en-US')
                    : 0}
                </td>
              </tr>
              <tr>
                <td className={textCell}>
                  <Box display="grid" alignItems="center">
                    <Sbt />
                    SBT
                  </Box>
                </td>
                <td>
                  {(userPools.find(
                    ({ poolAddress }) => poolAddress === pool?.address
                  )?.userUnclaimedSbt as number) > 100
                    ? Number(
                        unitParser.from(
                          userPools.find(
                            ({ poolAddress }) => poolAddress === pool?.address
                          )?.userUnclaimedSbt as number,
                          8
                        )
                      ).toLocaleString('en-US')
                    : 0}
                </td>
                <td className={textCell}>SBT APR</td>
                <td>
                  {pool?.aprSbt
                    ? roundOff(pool.aprSbt).toLocaleString('en-US')
                    : 0}
                </td>
              </tr>
              <tr>
                <td colSpan={2} />
                <td className={textCell}>Base APY</td>
                <td>
                  {Number(pool?.baseAPY.toFixed(2)).toLocaleString('en-US')}
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            disabled={claimDisabled}
            onClick={() => pool && handleClaim(pool)}
            buttonType="secondary"
          >
            Claim Rewards
          </Button>
        </div>
      </>
    </ModalBase>
  );
};
