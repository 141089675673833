export const shortenAddress = (
  address: string,
  leftPosition = 3,
  rightPosition = 28
) =>
  address
    ?.replace(
      address.split('').splice(leftPosition, rightPosition).join('') as string,
      '...'
    )
    .toLowerCase();
