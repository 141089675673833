import { FC, InputHTMLAttributes, ReactNode, memo } from 'react';
import cx from 'classnames';
import {
  input,
  label as labelStyles,
  root,
  suffix as suffixStyles,
  suffixWithLabel as suffixWithLabelStyles,
} from './input.css';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  suffix?: ReactNode;
};

export const Input: FC<Props> = memo(
  ({ type = 'text', className, label, suffix, ...props }) => (
    <div className={root}>
      {label && (
        <label className={labelStyles} htmlFor={props.id}>
          {label}
        </label>
      )}
      <input className={cx(input, className)} type={type} {...props} />
      {suffix && (
        <span className={label ? suffixWithLabelStyles : suffixStyles}>
          {suffix}
        </span>
      )}
    </div>
  )
);
