import { Box } from '../../components/primitives/box';
import { Input } from '../../components/primitives/input/input';
import { Radio } from '../../components/primitives/radio/radio';
import { Tag } from '../../components/primitives/tag/tag';
import {
  br,
  description,
  h1,
  radioContainer,
  root,
  searchPanel,
  text,
  subtitle,
  tagTVL,
  tagTVLMobile,
  tagsContainer,
} from './page-main.css';
import { useEffect, useState } from 'react';
import { unitParser } from '../../utils/unit-parser';
import { useAuth, useInfo, useNotifier, useUser } from '../../store/store';
import { NotifierAboutBeta } from '../../components/primitives/notifier-about-beta/notifier-about-beta';
import { Staking } from '../../components/containers/staking/staking';
import { StakingSbt } from '../../components/containers/staking-sbt/staking-sbt';
import { Convert } from '../../components/containers/convert/convert';

export const PageMain = () => {
  const { updateInfo, ...info } = useInfo((state) => state);
  const { updateUserInfo, updateBalances } = useUser((state) => state);
  const { setConfirmed, confirmed } = useNotifier((state) => state);
  const [activeCurrency, setActiveCurrency] = useState('ALL');
  const auth = useAuth(({ auth }) => auth);
  const [search, setSearch] = useState('');

  useEffect(() => {
    updateInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (auth?.address) {
      updateUserInfo(auth.address);
      updateBalances(auth.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    search && setSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCurrency]);

  return (
    <main className={root}>
      <div className={text}>
        <h1 className={h1}>
          Non-custodial platform where <br className={br} /> you can do more
          with your money
        </h1>
        <p className={subtitle}>Earn rewards with better boost</p>
        <div className={tagTVLMobile}>
          <Tag
            name="TVL"
            value={`${Math.trunc(info.tvl).toLocaleString('en-US')}$`}
          />
        </div>
        <Box className={tagsContainer}>
          <div className={tagTVL}>
            <Tag
              name="TVL"
              value={`${Math.trunc(info.tvl).toLocaleString('en-US')}$`}
            />
          </div>
          <Tag
            name="TOTAL REVENUE"
            value={`${Math.trunc(info.totalEarned).toLocaleString('en-US')}$`}
          />
          <Tag
            name="WX STAKED"
            value={
              info.totalWxLocked > 100
                ? Math.trunc(
                    Number(unitParser.from(info.totalWxLocked, 8))
                  ).toLocaleString('en-US')
                : 0
            }
          />
          <Tag
            name="gWX"
            value={
              info.totalgWx > 100
                ? Math.trunc(
                    Number(unitParser.from(info.totalgWx, 8))
                  ).toLocaleString('en-US')
                : 0
            }
          />
        </Box>
        <p className={description}>
          Lock your SBT for 120 days to earn protocol rewards
        </p>
      </div>
      <StakingSbt />
      <div className={text}>
        <p className={description}>
          Convert your WX to sbWX to earn more with your WX
        </p>
      </div>
      <Convert />
      <div className={text}>
        <p className={description}>
          Optimise your yield across various protocols with the StakeBooster and
          SBT rewards
        </p>
        <div className={searchPanel}>
          <div className={radioContainer}>
            <Radio
              id="ALL"
              name="currency"
              onChange={(e) => setActiveCurrency(e.target.value)}
              checked={activeCurrency === 'ALL'}
              value="ALL"
            />
            {auth?.address && (
              <Radio
                id="MY POOLS"
                name="currency"
                onChange={(e) => setActiveCurrency(e.target.value)}
                checked={activeCurrency === 'MY POOLS'}
                value="MY POOLS"
              />
            )}
            <Radio
              id="SBT"
              name="currency"
              onChange={(e) => setActiveCurrency(e.target.value)}
              checked={activeCurrency === 'SBT'}
              value="SBT"
            />
            <Radio
              id="WX"
              name="WX"
              onChange={(e) => setActiveCurrency(e.target.value)}
              checked={activeCurrency === 'WX'}
              value="WX"
            />
            <Radio
              id="USD"
              name="USD"
              onChange={(e) => setActiveCurrency(e.target.value)}
              checked={activeCurrency === 'USD'}
              value="USD"
            />
            <Radio
              id="WAVES"
              name="WAVES"
              onChange={(e) => setActiveCurrency(e.target.value)}
              checked={activeCurrency === 'WAVES'}
              value="WAVES"
            />
            <Radio
              id="ETH"
              name="ETH"
              onChange={(e) => setActiveCurrency(e.target.value)}
              checked={activeCurrency === 'ETH'}
              value="ETH"
            />
          </div>
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ justifySelf: 'start' }}
            placeholder="Search..."
          />
        </div>
      </div>
      <Staking search={search} activeCurrency={activeCurrency} />
      {!confirmed && (
        <NotifierAboutBeta
          onConfirm={() => {
            setConfirmed(true);
          }}
        />
      )}
    </main>
  );
};
