import "src/themes/main.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/themes/main.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA32U246bMBCG7/cpRqpWSqQl4hQg5KZqlrxGZMCAN+ZQ22mSVnn32mYNSbytB4Tg//TPGI/9/YivFUMt5tAi0h1YL5DAh0NwruKmceHPC4D7qh8AgqGOVz1rUxi5hVviermV4k3envsfMoju2NvLSmcTDW7xjiLOTUZPGzhO0dOeOTlFxdFIfgrfXD22d8i5IXO9gUQqPbYPLifseIYJJZNt9v5+bzG+YdaSed9k6yyzmMAwkWT8MNiFNhMaJlY+SRbsfYtZGyYZc3nZ+z1TM3Sda95IJvmhwmKmmpFkgliFxUw154rJVFjMVHMhmVgPi5lqLiUTZiosJjIMlky0U2ExsWEqlctXYTGJYWq1Xrpoi9kYplG5AhUjgzrSIkH6ztFt9kmRFBClqk1bDsUpJ4WT498Es4W7Ct/AldfKfwNv+WzCB9J1mBmfj/TrveJxoKTDiAHpKtLJvhyN+IAK7FymHj+mEA2Xe423RqMpJI9aWxpNbibPfRRpbcQuBT98FC/UiL0Un2wvszqkEETP6iz/TCH0LXnWmZyO1s2unn6cIbje1PpMIOp7Cr8QW/xzmZazVUPKEk+K0D4FJbJihguxcEGHXrChN+Yo5z09jX//TEohu0MfGQ0mdSM+X3o5ESKu+u32F6Lyx5sGBQAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "src/pages/main/page-main.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/pages/main/page-main.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51UTY+bMBS851f4UimR1isgH5uQS6VeW6mHqtfIBAe8a2xkTGFb5b/XxpgE7GzSnjb75DfvzfBmnkuUYVggwg6Cc3k4oFe+XbUB+DMDoEAtbEgq8xiEwXpTtvuuKDLCYhAAVEu+n51nzxcMibLqC2dS/cbCgoU9mO6DCZeSFzFYbQ1cSqqSovcYZIKkuqD/QokLVZUYHjmtC1bFQOASIznXM+GJUPoECsLUgvNwvS7bJxCexGKh+00HzFCphpgZgjdX/08X/vHzq9006jYdVmKcYe/7bzwhFNuu5bgrofz4tvcwdoZXGIlj/h0xTC3Waow1EsVwp7yJNaOurEmFow8zjDPl0TyBUsKdz7MejySMEk3bHWqUHeb24nKRYqHGPTiLogTT64MQJMvlgHZXsxRXR0FKSTizoJsO7qRmwYr8xv+iSB5akJcpyDIagUiuKQd+4MgBTgbK27s3VdWJJPJyTzufX+w6Ds3xeeJ28DDqYEqUpoRl2q/2/ecCpwSBuTLQxd6RknrRdTxm5/8w6m43MqpDcdPfwNjD4VAdbBz22XF2tnW87POlt81naeezOb03HezxMACIkoxBojRTOlUSCWnqr3UlyeldacgkZsoMVYmOGCZYNhiz/UVtrxlvfgn9GkRRZPUzrg292n0UDV4uH+0zHFXwyRQSGxJlCypOSQp+ITGHelUuYELR8e1wWDanlzyP+ttIVC0TvGapeRWPWppcqWhbln3LcOpLy7gfG1zxH9QwO0FNvFZi+U/qfopNcyzwnnbgwb6ZZE53tPIuNw2vSVZtLdNpWvmpThPrAef4g8sN8Y3frk5WXaeVaTj/BRG2T76gCAAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var br = 'page-main_br__ajo84x8';
export var description = 'page-main_description__ajo84x6';
export var h1 = 'page-main_h1__ajo84x7';
export var radioContainer = 'page-main_radioContainer__ajo84x5';
export var root = 'page-main_root__ajo84x0';
export var searchPanel = 'page-main_searchPanel__ajo84x4';
export var subtitle = 'page-main_subtitle__ajo84x9';
export var tagTVL = 'page-main_tagTVL__ajo84x2';
export var tagTVLMobile = 'page-main_tagTVLMobile__ajo84x3';
export var tagsContainer = 'page-main_tagsContainer__ajo84x1';
export var text = 'page-main_text__ajo84xa';