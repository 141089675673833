import { FC } from 'react';
import { root, name as nameStyles } from './tag.css';

export const Tag: FC<{ name: string; value: string | number }> = ({
  name,
  value,
}) => (
  <div className={root}>
    <span className={nameStyles}>{name}:</span>
    <span>{value}</span>
  </div>
);
