import { FC } from 'react';
import {
  arrow,
  controls,
  hint,
  hintMobile,
  inputs,
  mainInfo,
  root,
} from './convert.css';
import { Input } from '../input/input';
import { Button } from '../button';
import { Box } from '../box';
import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';

type Props = {
  wxAmount: number | '';
  setWxAmount: (amount: number | '') => void;
  sbWXAmount: number | '';
  setSbWXAmount: (amount: number | '') => void;
  onConvert: () => void;
  convertDisabled: boolean;
  onSetMaxAmount: () => void;
};

export const Convert: FC<Props> = ({
  wxAmount,
  setWxAmount,
  sbWXAmount,
  setSbWXAmount,
  onConvert,
  convertDisabled,
  onSetMaxAmount,
}) => {
  return (
    <section className={root}>
      <div className={controls}>
        <div className={inputs}>
          <Input
            label="WX to convert"
            value={wxAmount}
            onChange={(e) => {
              setWxAmount(e.target.value === '' ? '' : Number(e.target.value));
            }}
            type="number"
            min={0}
            suffix={
              <Button
                onClick={onSetMaxAmount}
                buttonType="secondary"
                buttonSize="small"
              >
                MAX
              </Button>
            }
          />
          <ArrowDown
            className={arrow}
            style={{ marginTop: 8, marginBottom: -8 }}
          />
          <Input
            label="sbWX you will get"
            value={sbWXAmount}
            onChange={(e) => {
              setSbWXAmount(
                e.target.value === '' ? '' : Number(e.target.value)
              );
            }}
            type="number"
            min={0}
          />
        </div>
        <Box display="grid" direction="vertical" justifyContent="stretch">
          <Button disabled={convertDisabled} onClick={onConvert}>
            Convert
          </Button>
        </Box>
      </div>
      <Box
        className={mainInfo}
        display="grid"
        direction="vertical"
        alignContent="between"
      >
        <Box display="grid" direction="vertical">
          <p>
            By providing liquidity in sbWX/WX pool you will get extra rewards
          </p>
          <ul>
            <li>+ Protocol Revenue share</li>
            <li>+ SBT Rewards</li>
          </ul>
        </Box>
        <Box className={hint} display="grid" direction="vertical">
          <p>
            <span style={{ color: '#ED1A72', paddingRight: 6 }}>!</span>
            Converting WX to sbWX is irreversible.
          </p>
          <p>
            Secondary markets however exist to allow the exchange of sbWX for WX
            at varying market rates.
          </p>
        </Box>
      </Box>
      <Box className={hintMobile} display="grid" direction="vertical">
        <p>
          <span style={{ color: '#ED1A72', paddingRight: 6 }}>!</span>
          Converting WX to sbWX is irreversible.
        </p>
        <p>
          Secondary markets however exist to allow the exchange of sbWX for WX
          at varying market rates.
        </p>
      </Box>
    </section>
  );
};
