import { FC } from 'react';
import {
  controls,
  hint,
  hintTd,
  link,
  mainInfo,
  primaryTd,
  radios,
  root,
  table,
  tdWithIcon,
  tokensInfo,
  tokensTable,
} from './staking-sbt.css';
import { Radio } from '../radio/radio';
import { Input } from '../input/input';
import { Button } from '../button';
import { Box } from '../box';
import {
  AuthStore,
  Pool,
  SbtPoolByUser,
  UserPool,
  UserStore,
} from '../../../store/types';
import { unitParser } from '../../../utils/unit-parser';
import { roundOff } from '../../../utils/round-off';
import { ReactComponent as Sbt } from '../../../assets/icons/sbt.svg';
import { ReactComponent as WavesExchange } from '../../../assets/icons/waves-exchange.svg';

type Props = {
  selectedAction: 'Lock' | 'Unlock';
  setAction: (action: 'Lock' | 'Unlock') => void;
  amount: number | '';
  setAmount: (amount: number | '') => void;
  actionDisabled: boolean;
  onExecuteAction: () => void;
  onClaimRewards: () => void;
  claimRewardsDisabled: boolean;
  onClaimUnlocked: () => void;
  claimUnlockedDisabled: boolean;
  userInfo?: Omit<UserStore, 'updateUserInfo'>;
  sbtPool?: Pool;
  sbtPoolRootInfo: SbtPoolByUser;
  auth: AuthStore['auth'];
  userSbtPool?: UserPool;
};

export const StakingSbt: FC<Props> = ({
  selectedAction,
  setAction,
  amount,
  setAmount,
  actionDisabled,
  onExecuteAction,
  onClaimRewards,
  claimRewardsDisabled,
  onClaimUnlocked,
  claimUnlockedDisabled,
  userInfo,
  sbtPool,
  sbtPoolRootInfo,
  auth,
  userSbtPool,
}) => {
  const handleSetMaxAmount = async () => {
    if (selectedAction === 'Unlock') {
      return setAmount(
        sbtPoolRootInfo.sbtPoolLockAmount > 100
          ? Number(unitParser.from(sbtPoolRootInfo.sbtPoolLockAmount, 8))
          : 0
      );
    }

    if (selectedAction === 'Lock') {
      const sbtPoolTokenBalance = userInfo?.balances.find(
        ({ assetId }) => assetId === sbtPool?.token
      )?.balance;

      setAmount(Number(unitParser.from(sbtPoolTokenBalance ?? 0, 8)));
    }
  };

  return (
    <section className={root}>
      <div className={controls}>
        <Box
          display="grid"
          direction="vertical"
          space="md"
          justifyContent="stretch"
        >
          <div className={radios}>
            <Radio
              id="Lock"
              checked={selectedAction === 'Lock'}
              onChange={(e) => setAction(e.target.value as 'Lock')}
              value="Lock"
              name="action-sbt"
            />
            <Radio
              id="Unlock"
              checked={selectedAction === 'Unlock'}
              onChange={(e) => setAction(e.target.value as 'Unlock')}
              value="Unlock"
              name="action-sbt"
            />
          </div>
          <Input
            value={amount}
            onChange={(e) =>
              setAmount(e.target.value === '' ? '' : Number(e.target.value))
            }
            type="number"
            suffix={
              <Button
                onClick={handleSetMaxAmount}
                buttonType="secondary"
                buttonSize="small"
              >
                MAX
              </Button>
            }
          />
        </Box>
        <Box display="grid" direction="vertical" justifyContent="stretch">
          {auth && selectedAction === 'Unlock' && (
            <p className={hint}>
              Available to claim:{' '}
              {userInfo?.availableToUnlockAmount ?? 0 > 100
                ? Number(
                    unitParser.from(userInfo?.availableToUnlockAmount ?? 0, 8)
                  ).toLocaleString('en-US')
                : 0}{' '}
              SBT
            </p>
          )}
          <Button disabled={actionDisabled} onClick={onExecuteAction}>
            {selectedAction}
          </Button>
          {selectedAction === 'Unlock' && (
            <Button
              disabled={claimUnlockedDisabled}
              onClick={onClaimUnlocked}
              buttonType="secondary"
            >
              Claim
            </Button>
          )}
        </Box>
      </div>
      <div className={mainInfo}>
        <p>
          By locking your SBT you start earning protocol revenue. Protocol
          revenue generating from all WX earned by LP.
        </p>
        <table className={table}>
          <tbody>
            <tr>
              <td className={hintTd}>APR</td>
              <td className={primaryTd}>
                {roundOff(sbtPool?.apr ?? 0).toLocaleString('en-US')}%
              </td>
            </tr>
            <tr>
              <td className={hintTd}>TVL</td>
              <td className={primaryTd}>
                {Number(sbtPool?.tvl.toFixed(2)).toLocaleString('en-US')}$
              </td>
            </tr>
            <tr>
              <td style={{ padding: 10 }} />
              <td />
            </tr>
            <tr>
              <td className={hintTd}>Total earning</td>
              <td>
                {roundOff(sbtPool?.totalEarned ?? 0).toLocaleString('en-US')}$
              </td>
            </tr>
            <tr>
              <td className={hintTd}>Contract</td>
              <td>
                <a
                  className={link}
                  href={`https://wavesexplorer.com/en/addresses/${sbtPool?.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
            <tr>
              <td className={hintTd}>Documentation</td>
              <td>
                <a
                  className={link}
                  href="https://stakebooster.gitbook.io/untitled/sbt-staking"
                  target="_blank"
                  rel="noreferrer"
                >
                  Link
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {auth && (
        <div className={tokensInfo}>
          <Box
            display="grid"
            direction="vertical"
            justifyContent="stretch"
            space="lg"
          >
            <table className={tokensTable}>
              <tbody>
                <tr>
                  <td className={hintTd}>Claimed</td>
                  <td className={tdWithIcon}>
                    <WavesExchange />
                    {userInfo?.sbtPoolClaimedWx ?? 0 > 100
                      ? Number(
                          unitParser.from(sbtPoolRootInfo.sbtPoolClaimedWx, 8)
                        ).toLocaleString('en-US')
                      : 0}
                    <span>WX</span>
                  </td>
                </tr>
                <tr>
                  <td className={hintTd}>Unclaimed</td>
                  <td className={tdWithIcon}>
                    <WavesExchange />
                    {userInfo?.sbtPoolUnclaimedWx ?? 0 > 100
                      ? Number(
                          unitParser.from(sbtPoolRootInfo.sbtPoolUnclaimedWx, 8)
                        ).toLocaleString('en-US')
                      : 0}
                    <span>WX</span>
                  </td>
                </tr>
                <tr>
                  <td className={hintTd}>My lock</td>
                  <td className={tdWithIcon}>
                    <Sbt />
                    {sbtPoolRootInfo.sbtPoolLockAmount > 100
                      ? Number(
                          unitParser.from(sbtPoolRootInfo.sbtPoolLockAmount, 8)
                        ).toLocaleString('en-US')
                      : 0}
                    <span>SBT</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              disabled={claimRewardsDisabled}
              onClick={onClaimRewards}
              buttonType="secondary"
            >
              Claim Rewards
            </Button>
          </Box>
        </div>
      )}
    </section>
  );
};
