import { Signer } from '@waves/signer';
import { useAuth, useSigner } from '../store/store';
import { Wallet } from '../store/types';
import { ProviderKeeperMobile } from '@keeper-wallet/provider-keeper-mobile';
import { ProviderWeb } from '@waves.exchange/provider-web';
import { ProviderCloud } from '@waves.exchange/provider-cloud';

export const useConnectWallet = () => {
  const setAuth = useAuth(({ setAuth }) => setAuth);
  const setSigner = useSigner(({ setSigner }) => setSigner);

  return async (type: Wallet) => {
    try {
      if (type === 'desktop') {
        const auth = await KeeperWallet.auth({
          data: `${new Date().getTime()}`,
        });

        return setAuth({ ...auth, type });
      }

      const signer = new Signer();

      if (type === 'mobile') {
        signer.setProvider(new ProviderKeeperMobile());
      }

      if (type === 'web') {
        signer.setProvider(new ProviderWeb());
      }

      if (type === 'cloud') {
        signer.setProvider(new ProviderCloud());
      }

      const auth = await signer.login();
      setSigner(signer);
      setAuth({ type, ...auth });

      return signer;
    } catch (e) {
      console.log(e);
    }
  };
};
