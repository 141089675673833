import { FC, memo, useEffect, useState } from 'react';
import { Staking as PStaking } from '../../primitives/staking/staking';
import { Pool } from '../../../store/types';
import { useAuth, useInfo, useUser, useSigner } from '../../../store/store';
import { unitParser } from '../../../utils/unit-parser';
import { parseTx } from '../../../utils/parse-tx';
import { waitForTx } from '@waves/waves-transactions';
import { useWidth } from '../../../hooks';
import { ModalStaking } from '../../modals/staking';
import { getAuthType } from '../../../utils/get-auth-type';

export type HeaderTitle =
  | 'TVL'
  | 'APR'
  | 'My deposits'
  | 'Unclaimed'
  | 'Claimed';

export type SortValue = 'asc' | 'desc';

export type Sort = [HeaderTitle, SortValue];

const SortKeys = {
  TVL: 'tvl',
  APR: 'apr',
  'My deposits': 'userLockedAmountUSD',
  Unclaimed: 'userUnclaimedUSD',
  Claimed: 'userClaimedUSD',
} as const;

export const Staking: FC<{ activeCurrency: string; search: string }> = memo(
  ({ activeCurrency, search }) => {
    const [action, setAction] = useState<'Stake' | 'Unstake'>('Stake');
    const [uiPools, setUiPools] = useState<
      (Pool & { expand: boolean; amountForExecute: number | '' })[]
    >([]);
    const { pools, updateInfo, mainContractAddress, sbtPoolAddress } = useInfo(
      (state) => state
    );
    const [actionLoading, setActionLoading] = useState(false);
    const [claimLoading, setClaimLoading] = useState(false);
    const { updateUserInfo, ...userInfo } = useUser((state) => state);
    const auth = useAuth(({ auth }) => auth);
    const signer = useSigner(({ signer }) => signer);
    const { width, desktopWidth } = useWidth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedPool, setSelectedPool] = useState<Pool>();
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [sortBy, setSortBy] = useState<Sort>(['TVL', 'desc']);

    const handleSetMaxAmount = async (pool: Pool) => {
      if (action === 'Unstake') {
        return handleSetAmount({
          amount: Number(
            unitParser.from(
              userInfo.pools.find(
                ({ poolAddress }) => poolAddress === pool.address
              )?.userLockedAmountLP ?? 0,
              8
            )
          ),
          pool,
        });
      }

      if (action === 'Stake') {
        const poolTokenBalance = userInfo.balances.find(
          ({ assetId }) => assetId === pool.token
        )?.balance;

        handleSetAmount({
          amount: Number(unitParser.from(poolTokenBalance ?? 0, 8)),
          pool,
        });
      }
    };

    const handleSetAmount = ({
      amount,
      pool,
    }: {
      amount: number | '';
      pool: Pool;
    }) => {
      setUiPools(
        uiPools.map((uiPool) =>
          uiPool.address === pool.address
            ? { ...uiPool, amountForExecute: amount }
            : uiPool
        )
      );
    };

    const handleExecuteAction = async (
      pool: Pool & { amountForExecute: number | '' }
    ) => {
      if (
        action === 'Stake' &&
        pool.amountForExecute !== '' &&
        pool.amountForExecute > 0
      ) {
        try {
          setActionLoading(true);

          const txData = {
            type: 16 as const,
            data: {
              dApp: pool.address,
              call: {
                function: 'deposit',
                args: [],
              },
              payment: [
                {
                  assetId: pool.token,
                  amount: Math.trunc(unitParser.to(pool.amountForExecute, 8)),
                },
              ],
            },
          };

          let txId = '';

          if (getAuthType.isKeeper(auth?.type)) {
            const tx = await KeeperWallet.signAndPublishTransaction(txData);
            txId = parseTx(tx).id;
          }

          if (getAuthType.isNotKeeper(auth?.type)) {
            const transaction = (await signer
              ?.invoke(txData.data)
              .broadcast()) ?? { id: '' };

            txId = (transaction as { id: string }).id ?? '';
          }

          await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });

          setUiPools(
            uiPools.map((uiPool) =>
              uiPool.address === pool.address
                ? { ...uiPool, amountForExecute: '' }
                : uiPool
            )
          );
          updateInfo();
          auth && updateUserInfo(auth.address);
        } catch (e) {
          console.error(e);
        } finally {
          setActionLoading(false);
        }
      }

      if (
        action === 'Unstake' &&
        pool.amountForExecute !== '' &&
        pool.amountForExecute > 0
      ) {
        try {
          setActionLoading(true);

          const txData = {
            type: 16 as const,
            data: {
              dApp: pool.address,
              call: {
                function: 'withdraw',
                args: [
                  {
                    type: 'integer' as const,
                    value: Math.trunc(unitParser.to(pool.amountForExecute, 8)),
                  },
                ],
              },
              payment: [],
            },
          };

          let txId = '';

          if (getAuthType.isKeeper(auth?.type)) {
            const tx = await KeeperWallet.signAndPublishTransaction(txData);
            txId = parseTx(tx).id;
          }

          if (getAuthType.isNotKeeper(auth?.type)) {
            const transaction = (await signer
              ?.invoke(txData.data)
              .broadcast()) ?? { id: '' };

            txId = (transaction as { id: string }).id ?? '';
          }

          await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });

          setUiPools(
            uiPools.map((uiPool) =>
              uiPool.address === pool.address
                ? { ...uiPool, amountForExecute: '' }
                : uiPool
            )
          );
          updateInfo();
          auth && updateUserInfo(auth.address);
        } catch (e) {
          console.error(e);
        } finally {
          setActionLoading(false);
        }
      }
    };

    const handleClaim = async (pool: Pool) => {
      try {
        setClaimLoading(true);

        const txData = {
          type: 16 as const,
          data: {
            dApp: pool.address,
            call: {
              function: 'claim',
              args: [],
            },
            payment: [],
          },
        };

        let txId = '';

        if (getAuthType.isKeeper(auth?.type)) {
          const tx = await KeeperWallet.signAndPublishTransaction(txData);
          txId = parseTx(tx).id;
        }

        if (getAuthType.isNotKeeper(auth?.type)) {
          const transaction = (await signer
            ?.invoke(txData.data)
            .broadcast()) ?? { id: '' };

          txId = (transaction as { id: string }).id ?? '';
        }

        await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });

        updateInfo();
        auth && updateUserInfo(auth.address);
      } catch (e) {
        console.error(e);
      } finally {
        setClaimLoading(false);
      }
    };

    useEffect(() => {
      if (pools.length && isFirstRender) {
        setUiPools(
          pools
            .filter(({ address }) => address !== sbtPoolAddress)
            .sort((a, b) => {
              if (a.tvl < b.tvl) {
                return 1;
              }

              if (a.tvl > b.tvl) {
                return -1;
              }

              return 0;
            })
            .map((pool, i) => ({
              ...pool,
              expand: i === 0,
              amountForExecute: '',
            }))
        );
        setIsFirstRender(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pools]);

    useEffect(() => {
      if (activeCurrency === 'MY POOLS') {
        const userPoolsAdressesWithDeposit = userInfo.pools
          .filter(({ userLockedAmountUSD }) => userLockedAmountUSD > 0)
          .map(({ poolAddress }) => poolAddress);

        return setUiPools(
          pools
            .filter(({ address }) => address !== sbtPoolAddress)
            .map((pool, i) => ({
              ...pool,
              expand: i === 0,
              amountForExecute: '' as const,
            }))
            .filter(({ address }) =>
              userPoolsAdressesWithDeposit.includes(address)
            )
        );
      }

      if (activeCurrency !== 'ALL') {
        return setUiPools(
          pools
            .filter(({ address }) => address !== sbtPoolAddress)
            .map((pool, i) => ({
              ...pool,
              expand: i === 0,
              amountForExecute: '' as const,
            }))
            .filter(({ name }) => name.includes(activeCurrency))
        );
      }

      setUiPools(
        pools
          .filter(({ address }) => address !== sbtPoolAddress)
          .map((pool, i) => ({
            ...pool,
            expand: i === 0,
            amountForExecute: '',
          }))
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCurrency]);

    useEffect(() => {
      if (activeCurrency !== 'ALL') {
        setUiPools(
          pools
            .filter(({ address }) => address !== sbtPoolAddress)
            .map((pool, i) => ({
              ...pool,
              expand: i === 0,
              amountForExecute: '' as const,
            }))
            .filter(
              ({ name }) =>
                name.toLowerCase().includes(search.toLowerCase()) &&
                name.includes(activeCurrency)
            )
        );
      } else {
        setUiPools(
          pools
            .filter(({ address }) => address !== sbtPoolAddress)
            .map((pool, i) => ({
              ...pool,
              expand: i === 0,
              amountForExecute: '' as const,
            }))
            .filter(({ name }) =>
              name.toLowerCase().includes(search.toLowerCase())
            )
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
      if (uiPools.length) {
        if (sortBy[0] === 'APR' || sortBy[0] === 'TVL') {
          const sortKey = SortKeys[sortBy[0]];
          const sortValue = sortBy[1];

          return setUiPools(
            uiPools
              .sort((a, b) => {
                if (a[sortKey] > b[sortKey]) {
                  return sortValue === 'asc' ? 1 : -1;
                }

                if (a[sortKey] < b[sortKey]) {
                  return sortValue === 'asc' ? -1 : 1;
                }

                return 0;
              })
              .map((pool, i) => ({ ...pool, expand: i === 0 }))
          );
        }

        const sortKey = SortKeys[sortBy[0]];
        const sortValue = sortBy[1];

        setUiPools(
          uiPools
            .sort((a, b) => {
              const firstUserPool = userInfo.pools.find(
                ({ poolAddress }) => poolAddress === a.address
              );
              const secondUserPool = userInfo.pools.find(
                ({ poolAddress }) => poolAddress === b.address
              );

              if (firstUserPool && secondUserPool) {
                if (firstUserPool[sortKey] > secondUserPool[sortKey]) {
                  return sortValue === 'asc' ? 1 : -1;
                }

                if (firstUserPool[sortKey] < secondUserPool[sortKey]) {
                  return sortValue === 'asc' ? -1 : 1;
                }
              }

              return 0;
            })
            .map((pool, i) => ({ ...pool, expand: i === 0 }))
        );
      }
    }, [sortBy]);

    const handleHeaderRowClick = (pool: Pool) => {
      setSelectedPool(pool);
      setIsModalOpen(true);
    };

    return (
      <>
        <PStaking
          pools={uiPools}
          actionDisabled={actionLoading}
          onExecuteAction={handleExecuteAction}
          setAmount={handleSetAmount}
          selectedAction={action}
          setAction={setAction}
          onClaim={handleClaim}
          claimDisabled={claimLoading}
          setPools={setUiPools}
          mainContractAddress={mainContractAddress}
          userInfo={userInfo}
          onHeaderRowClick={
            width < desktopWidth ? handleHeaderRowClick : undefined
          }
          sortBy={sortBy}
          setSortBy={setSortBy}
          onSetMaxAmount={handleSetMaxAmount}
        />
        <ModalStaking
          pool={selectedPool}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </>
    );
  }
);
