import { FC, Fragment } from 'react';
import { ReactComponent as Minus } from '../../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';
import { ReactComponent as WavesExchange } from '../../../assets/icons/waves-exchange.svg';
import { ReactComponent as Sbt } from '../../../assets/icons/sbt.svg';
import { ReactComponent as SortByAsc } from '../../../assets/icons/arrow-bars-up.svg';
import { ReactComponent as SortByDesc } from '../../../assets/icons/arrow-bars-down.svg';

import {
  controls,
  description,
  expandButton,
  expandIcon,
  header,
  headerRow,
  headerTitleDesktop,
  hintTd,
  innerRow,
  link,
  mainInfo,
  primaryTile,
  radios,
  root,
  separator,
  separatorExpand,
  table,
  tdWithIcon,
  tokensInfo,
  tokensTable,
} from './staking.css';
import { Radio } from '../radio/radio';
import { Input } from '../input/input';
import { Button } from '../button';
import { Box } from '../box';
import { Pool, UserStore } from '../../../store/types';
import { roundOff } from '../../../utils/round-off';
import { unitParser } from '../../../utils/unit-parser';
import { theme } from '../../../themes/main.css';
import type { Sort } from '../../containers/staking/staking';

type Props = {
  selectedAction: 'Stake' | 'Unstake';
  setAction: (action: 'Stake' | 'Unstake') => void;
  setAmount: (params: { amount: number | ''; pool: Pool }) => void;
  actionDisabled: boolean;
  onExecuteAction: (pool: Pool & { amountForExecute: number | '' }) => void;
  pools: (Pool & { expand: boolean; amountForExecute: number | '' })[];
  userInfo: Omit<UserStore, 'updateUserInfo'>;
  onClaim: (pool: Pool) => void;
  claimDisabled: boolean;
  setPools: (
    pools: (Pool & { expand: boolean; amountForExecute: number | '' })[]
  ) => void;
  mainContractAddress: string;
  onHeaderRowClick?: (pool: Pool) => void;
  sortBy: Sort;
  setSortBy: (sort: Sort) => void;
  onSetMaxAmount: (pool: Pool) => Promise<void>;
};

export const Staking: FC<Props> = ({
  selectedAction,
  setAction,
  setAmount,
  actionDisabled,
  onExecuteAction,
  pools,
  onClaim,
  claimDisabled,
  setPools,
  mainContractAddress,
  userInfo: { pools: userPools, ...userInfo },
  onHeaderRowClick,
  sortBy,
  setSortBy,
  onSetMaxAmount,
}) => {
  return (
    <section className={root}>
      <div className={header}>
        <p>Currency pair</p>
        <div
          className={headerTitleDesktop}
          onClick={() =>
            setSortBy(['Claimed', sortBy[1] === 'asc' ? 'desc' : 'asc'])
          }
        >
          <p>Claimed</p>
          {sortBy[0] === 'Claimed' &&
            (sortBy[1] === 'asc' ? (
              <SortByAsc color={theme.color.black} width={16} height={16} />
            ) : (
              <SortByDesc color={theme.color.black} width={16} height={16} />
            ))}
        </div>
        <div
          className={headerTitleDesktop}
          onClick={() =>
            setSortBy(['Unclaimed', sortBy[1] === 'asc' ? 'desc' : 'asc'])
          }
        >
          <p>Unclaimed</p>
          {sortBy[0] === 'Unclaimed' &&
            (sortBy[1] === 'asc' ? (
              <SortByAsc color={theme.color.black} width={16} height={16} />
            ) : (
              <SortByDesc color={theme.color.black} width={16} height={16} />
            ))}
        </div>
        <div
          className={headerTitleDesktop}
          onClick={() =>
            setSortBy(['My deposits', sortBy[1] === 'asc' ? 'desc' : 'asc'])
          }
        >
          <p>My deposits</p>
          {sortBy[0] === 'My deposits' &&
            (sortBy[1] === 'asc' ? (
              <SortByAsc color={theme.color.black} width={16} height={16} />
            ) : (
              <SortByDesc color={theme.color.black} width={16} height={16} />
            ))}
        </div>
        <Box
          alignItems="end"
          display="grid"
          onClick={() =>
            setSortBy(['APR', sortBy[1] === 'asc' ? 'desc' : 'asc'])
          }
        >
          <p>APR</p>
          {sortBy[0] === 'APR' &&
            (sortBy[1] === 'asc' ? (
              <SortByAsc color={theme.color.black} width={16} height={16} />
            ) : (
              <SortByDesc color={theme.color.black} width={16} height={16} />
            ))}
        </Box>
        <Box
          alignItems="end"
          display="grid"
          onClick={() =>
            setSortBy(['TVL', sortBy[1] === 'asc' ? 'desc' : 'asc'])
          }
        >
          <p>TVL</p>
          {sortBy[0] === 'TVL' &&
            (sortBy[1] === 'asc' ? (
              <SortByAsc color={theme.color.black} width={16} height={16} />
            ) : (
              <SortByDesc color={theme.color.black} width={16} height={16} />
            ))}
        </Box>
      </div>
      {pools.map((pool, i) => (
        <Fragment key={pool.name}>
          <div
            className={headerRow}
            onClick={() => {
              if (onHeaderRowClick) {
                return onHeaderRowClick(pool);
              }

              pool.expand
                ? setPools(
                    pools.map((p) =>
                      p.address === pool.address ? { ...p, expand: false } : p
                    )
                  )
                : setPools(
                    pools.map((p) =>
                      p.address === pool.address
                        ? { ...p, expand: true }
                        : { ...p, expand: false }
                    )
                  );
            }}
          >
            <p>{pool.name}</p>
            <p className={headerTitleDesktop}>
              {roundOff(
                userPools.find(
                  ({ poolAddress }) => poolAddress === pool.address
                )?.userClaimedUSD ?? 0
              ).toLocaleString('en-US')}
              $
            </p>
            <p className={headerTitleDesktop}>
              {roundOff(
                userPools.find(
                  ({ poolAddress }) => poolAddress === pool.address
                )?.userUnclaimedUSD ?? 0
              ).toLocaleString('en-US')}
              $
            </p>
            <p className={headerTitleDesktop}>
              {roundOff(
                userPools.find(
                  ({ poolAddress }) => poolAddress === pool.address
                )?.userLockedAmountUSD ?? 0
              ).toLocaleString('en-US')}
              $
            </p>
            <p className={primaryTile}>
              {pool.apr && roundOff(pool.apr).toLocaleString('en-US')}%
            </p>
            <p className={primaryTile}>
              {Number(pool?.tvl.toFixed(2)).toLocaleString('en-US')}$
            </p>
            <button className={expandButton}>
              {pool.expand ? (
                <Minus
                  width={15}
                  height={15}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPools(
                      pools.map((p) =>
                        p.address === pool.address ? { ...p, expand: false } : p
                      )
                    );
                  }}
                  className={expandIcon}
                />
              ) : (
                <Plus
                  width={15}
                  height={15}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPools(
                      pools.map((p) =>
                        p.address === pool.address
                          ? { ...p, expand: true }
                          : { ...p, expand: false }
                      )
                    );
                  }}
                  className={expandIcon}
                />
              )}
            </button>
          </div>
          {!pool.expand && i !== pools.length - 1 && (
            <div className={separator} />
          )}
          {pool.expand && (
            <>
              <div className={innerRow}>
                <div className={controls}>
                  <Box
                    display="grid"
                    direction="vertical"
                    space="md"
                    justifyContent="stretch"
                  >
                    <div className={radios}>
                      <Radio
                        id="Stake"
                        checked={selectedAction === 'Stake'}
                        onChange={(e) => setAction(e.target.value as 'Stake')}
                        value="Stake"
                        name="action"
                      />
                      <Radio
                        id="Unstake"
                        checked={selectedAction === 'Unstake'}
                        onChange={(e) => setAction(e.target.value as 'Unstake')}
                        value="Unstake"
                        name="action"
                      />
                    </div>
                    <Input
                      value={pool.amountForExecute}
                      onChange={(e) =>
                        setAmount({
                          amount:
                            e.target.value === '' ? '' : Number(e.target.value),
                          pool,
                        })
                      }
                      suffix={
                        <Button
                          onClick={() => onSetMaxAmount(pool)}
                          buttonType="secondary"
                          buttonSize="small"
                        >
                          MAX
                        </Button>
                      }
                      type="number"
                    />
                  </Box>
                  <Box
                    display="grid"
                    direction="vertical"
                    justifyContent="stretch"
                  >
                    <Button
                      disabled={actionDisabled}
                      onClick={() => onExecuteAction(pool)}
                    >
                      {selectedAction}
                    </Button>
                  </Box>
                </div>
                <div className={mainInfo}>
                  <p className={description}>
                    Deposit liquidity into the {pool.name} pool (without
                    staking), and then stake your LP tokens here to earn boosted
                    WX rewards
                  </p>
                  <table className={table}>
                    <tbody>
                      <tr>
                        <td className={hintTd}>Your LP Staked</td>
                        <td>
                          {Number(
                            unitParser.from(
                              userPools.find(
                                ({ poolAddress }) =>
                                  poolAddress === pool.address
                              )?.userLockedAmountLP ?? 0,
                              8
                            )
                          ).toLocaleString('en-US')}
                        </td>
                      </tr>
                      <tr>
                        <td className={hintTd}>Total LP Staked</td>
                        <td>
                          {pool.amount > 100
                            ? Number(
                                unitParser.from(pool.amount, 8)
                              ).toLocaleString('en-US')
                            : 0}
                        </td>
                      </tr>
                      <tr>
                        <td className={hintTd}>Total earning</td>
                        <td>
                          {roundOff(pool.totalEarned).toLocaleString('en-US')}$
                        </td>
                      </tr>
                      <tr>
                        <td className={hintTd}>Main contract</td>
                        <td>
                          <a
                            className={link}
                            href={`https://wavesexplorer.com/en/addresses/${mainContractAddress}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Link
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td className={hintTd}>Pool contract</td>
                        <td>
                          <a
                            className={link}
                            href={`https://wavesexplorer.com/en/addresses/${pool.address}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Link
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={tokensInfo}>
                  <Box
                    display="grid"
                    direction="vertical"
                    justifyContent="stretch"
                    space="lg"
                  >
                    <table className={tokensTable}>
                      <tbody>
                        <tr>
                          <td className={hintTd}>Base APY</td>
                          <td>
                            {Number(pool.baseAPY.toFixed(2)).toLocaleString(
                              'en-US'
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className={hintTd}>WX APR</td>
                          <td>
                            {roundOff(pool.aprWx).toLocaleString('en-US')}
                          </td>
                        </tr>
                        <tr>
                          <td className={hintTd}>SBT APR</td>
                          <td>
                            {roundOff(pool.aprSbt).toLocaleString('en-US')}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: 8 }}></td>
                        </tr>
                        {Boolean(userPools.length) && (
                          <>
                            <tr>
                              <td className={hintTd}>
                                <span className={tdWithIcon}>
                                  <WavesExchange />
                                  WX
                                </span>
                              </td>
                              <td>
                                {(userPools.find(
                                  ({ poolAddress }) =>
                                    poolAddress === pool.address
                                )?.userUnclaimedWx as number) > 100
                                  ? Number(
                                      unitParser.from(
                                        userPools.find(
                                          ({ poolAddress }) =>
                                            poolAddress === pool.address
                                        )?.userUnclaimedWx ?? 0,
                                        8
                                      )
                                    ).toLocaleString('en-US')
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td className={hintTd}>
                                <span className={tdWithIcon}>
                                  <Sbt />
                                  SBT
                                </span>
                              </td>
                              <td>
                                {userPools.find(
                                  ({ poolAddress }) =>
                                    poolAddress === pool.address
                                )?.userUnclaimedSbt ?? 0 > 100
                                  ? Number(
                                      unitParser.from(
                                        userPools.find(
                                          ({ poolAddress }) =>
                                            poolAddress === pool.address
                                        )?.userUnclaimedSbt ?? 0,
                                        8
                                      )
                                    ).toLocaleString('en-US')
                                  : 0}
                              </td>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                    <Button
                      disabled={claimDisabled}
                      onClick={() => onClaim(pool)}
                      buttonType="secondary"
                    >
                      Claim Rewards
                    </Button>
                  </Box>
                </div>
              </div>
              <div className={separatorExpand} />
            </>
          )}
        </Fragment>
      ))}
    </section>
  );
};
