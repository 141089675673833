import { FC } from 'react';
import { ReactComponent as Cross } from '../../../assets/icons/plus.svg';
import { headerDesktop, overlay, root, titleStyles } from './modal-base.css';
import { Props } from './modal-base.types';
import cx from 'classnames';
import { theme } from '../../../themes/main.css';
import { useBlockingComponent } from '../../../hooks';

export const ModalBase: FC<Props> = ({
  title,
  isOpen,
  onClose,
  children,
  width,
  className,
}) => {
  useBlockingComponent({ isOpen, close: onClose });

  return isOpen ? (
    <>
      <div className={overlay} onClick={onClose}>
        <Cross
          stroke={theme.color.white}
          onClick={onClose}
          style={{ transform: 'rotate(45deg)' }}
        />
      </div>
      <div style={width ? { width } : {}} className={cx(root, className)}>
        <div className={headerDesktop}>
          <p className={titleStyles}>{title}</p>
          <Cross
            stroke={theme.color.black}
            cursor="pointer"
            onClick={onClose}
            style={{ transform: 'rotate(45deg)' }}
          />
        </div>
        {children}
      </div>
    </>
  ) : null;
};
