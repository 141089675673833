import { FC, useState } from 'react';
import { StakingSbt as PStakingSbt } from '../../primitives/staking-sbt/staking-sbt';
import { useAuth, useInfo, useSigner, useUser } from '../../../store/store';
import { unitParser } from '../../../utils/unit-parser';
import { parseTx } from '../../../utils/parse-tx';
import { waitForTx } from '@waves/waves-transactions';
import { getAuthType } from '../../../utils/get-auth-type';

export const StakingSbt: FC = () => {
  const [amount, setAmount] = useState<'' | number>('');
  const [action, setAction] = useState<'Lock' | 'Unlock'>('Lock');
  const [loading, setLoading] = useState(false);
  const [claimRewardsLoading, setClaimRewardsLoading] = useState(false);
  const [claimUnlockedLoading, setClaimUnlockedLoading] = useState(false);
  const { sbtPoolAddress, updateInfo, pools } = useInfo((state) => state);
  const { updateUserInfo, ...userInfo } = useUser((state) => state);
  const auth = useAuth(({ auth }) => auth);
  const signer = useSigner(({ signer }) => signer);

  const handleExecuteAction = async () => {
    try {
      if (action === 'Lock' && amount !== '' && amount > 0) {
        setLoading(true);

        const txData = {
          type: 16 as const,
          data: {
            dApp: sbtPoolAddress,
            call: {
              function: 'deposit',
              args: [],
            },
            payment: [
              {
                assetId: process.env.REACT_APP_SBT_ASSET_ID as string,
                amount: Math.trunc(unitParser.to(amount, 8)),
              },
            ],
          },
        };

        let txId = '';

        if (getAuthType.isKeeper(auth?.type)) {
          const tx = await KeeperWallet.signAndPublishTransaction(txData);
          txId = parseTx(tx).id;
        }

        if (getAuthType.isNotKeeper(auth?.type)) {
          const transaction = (await signer
            ?.invoke(txData.data)
            .broadcast()) ?? { id: '' };

          txId = (transaction as { id: string }).id ?? '';
        }

        await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });
        setAmount('');
        updateInfo();
        auth && updateUserInfo(auth.address);
      }

      if (action === 'Unlock' && amount !== '' && amount > 0) {
        setLoading(true);

        const txData = {
          type: 16 as const,
          data: {
            dApp: sbtPoolAddress,
            call: {
              function: 'unlock',
              args: [
                {
                  type: 'integer' as const,
                  value: Math.trunc(unitParser.to(amount, 8)),
                },
              ],
            },
            payment: [],
          },
        };

        let txId = '';

        if (getAuthType.isKeeper(auth?.type)) {
          const tx = await KeeperWallet.signAndPublishTransaction(txData);
          txId = parseTx(tx).id;
        }

        if (getAuthType.isNotKeeper(auth?.type)) {
          const transaction = (await signer
            ?.invoke(txData.data)
            .broadcast()) ?? { id: '' };

          txId = (transaction as { id: string }).id ?? '';
        }

        await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });
        setAmount('');
        updateInfo();
        auth && updateUserInfo(auth.address);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleClaimRewards = async () => {
    try {
      setClaimRewardsLoading(true);

      const txData = {
        type: 16 as const,
        data: {
          dApp: sbtPoolAddress,
          call: {
            function: 'claim',
            args: [],
          },
          payment: [],
        },
      };

      let txId = '';

      if (getAuthType.isKeeper(auth?.type)) {
        const tx = await KeeperWallet.signAndPublishTransaction(txData);
        txId = parseTx(tx).id;
      }

      if (getAuthType.isNotKeeper(auth?.type)) {
        const transaction = (await signer?.invoke(txData.data).broadcast()) ?? {
          id: '',
        };

        txId = (transaction as { id: string }).id ?? '';
      }

      await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });
      updateInfo();
      auth && updateUserInfo(auth.address);
    } catch (e) {
      console.error(e);
    } finally {
      setClaimRewardsLoading(false);
    }
  };

  const handleClaimUnlocked = async () => {
    try {
      setClaimUnlockedLoading(true);

      const txData = {
        type: 16 as const,
        data: {
          dApp: sbtPoolAddress,
          call: {
            function: 'claim_unlocked',
            args: [],
          },
          payment: [],
        },
      };

      let txId = '';

      if (getAuthType.isKeeper(auth?.type)) {
        const tx = await KeeperWallet.signAndPublishTransaction(txData);
        txId = parseTx(tx).id;
      }

      if (getAuthType.isNotKeeper(auth?.type)) {
        const transaction = (await signer?.invoke(txData.data).broadcast()) ?? {
          id: '',
        };

        txId = (transaction as { id: string }).id ?? '';
      }

      await waitForTx(txId, { apiBase: 'https://nodes.wavesplatform.com' });
      updateInfo();
      auth && updateUserInfo(auth.address);
    } catch (e) {
      console.error(e);
    } finally {
      setClaimUnlockedLoading(false);
    }
  };

  return (
    <PStakingSbt
      amount={amount}
      setAmount={setAmount}
      selectedAction={action}
      setAction={setAction}
      actionDisabled={loading}
      onExecuteAction={handleExecuteAction}
      onClaimRewards={handleClaimRewards}
      claimRewardsDisabled={claimRewardsLoading}
      onClaimUnlocked={handleClaimUnlocked}
      claimUnlockedDisabled={claimUnlockedLoading}
      userInfo={userInfo}
      sbtPool={pools.find(({ address }) => address === sbtPoolAddress)}
      sbtPoolRootInfo={{
        sbtPoolClaimedWx: userInfo.sbtPoolClaimedWx,
        sbtPoolUnclaimedWx: userInfo.sbtPoolUnclaimedWx,
        sbtPoolLockAmount: userInfo.sbtPoolLockAmount,
      }}
      auth={auth}
      userSbtPool={userInfo.pools.find(
        ({ poolAddress }) => poolAddress === sbtPoolAddress
      )}
    />
  );
};
