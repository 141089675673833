import { FC, useState } from 'react';
import { Header as PHeader } from '../../primitives/header';
import { useAuth, useSigner } from '../../../store/store';

export const Header: FC = () => {
  const [isModalConnectWallet, setIsModalConnectWallet] = useState(false);
  const authStore = useAuth((state) => state);
  const signerStore = useSigner((state) => state);

  return (
    <PHeader
      isModalConnectWallet={isModalConnectWallet}
      setIsModalConnectWallet={setIsModalConnectWallet}
      {...authStore}
      {...signerStore}
    />
  );
};
