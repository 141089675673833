import { useEffect } from 'react';
import { useAuth, useSigner } from '../store/store';
import { Signer } from '@waves/signer';
import { ProviderKeeperMobile } from '@keeper-wallet/provider-keeper-mobile';
import { ProviderWeb } from '@waves.exchange/provider-web';
import { ProviderCloud } from '@waves.exchange/provider-cloud';

export const useInitialSigner = () => {
  const auth = useAuth(({ auth }) => auth);
  const setSigner = useSigner(({ setSigner }) => setSigner);

  useEffect(() => {
    if (auth) {
      const signer = new Signer();

      if (auth.type === 'mobile') {
        signer.setProvider(new ProviderKeeperMobile());
        setSigner(signer);
      }

      if (auth.type === 'web') {
        signer.setProvider(new ProviderWeb());
        setSigner(signer);
      }

      if (auth.type === 'cloud') {
        signer.setProvider(new ProviderCloud());
        setSigner(signer);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);
};
