import { CSSProperties, FC, ReactNode } from 'react';
import {
  adaptivePaddings,
  align,
  alignContent,
  block,
  flow,
  gap,
  grid,
  justifyContent as justifyContentVariants,
  justifyItems as justifyItemsVariants,
} from './box.css';
import cx from 'classnames';
import { Space } from '../../../themes/main.css';

type Props = {
  children: ReactNode;
  className?: string;
  direction?: keyof typeof flow;
  space?: keyof typeof gap;
  alignItems?: keyof typeof align;
  alignContent?: keyof typeof alignContent;
  adaptiveHPaddings?: boolean;
  vPaddings?: Space;
  justifyContent?: keyof typeof justifyContentVariants;
  justifyItems?: keyof typeof justifyItemsVariants;
  display?: CSSProperties['display'];
  onClick?: () => void;
};

export const Box: FC<Props> = ({
  direction = 'horizontal',
  space = 'xs',
  alignItems = 'start',
  alignContent: alignContentProp,
  adaptiveHPaddings = false,
  justifyContent = 'start',
  justifyItems,
  display = 'block',
  children,
  className,
  vPaddings,
  onClick,
}) => {
  const displayGrid = display === 'grid';

  return (
    <div
      onClick={onClick}
      className={cx(
        displayGrid ? flow[direction] : '',
        displayGrid ? gap[space] : '',
        displayGrid ? align[alignItems] : '',
        displayGrid && alignContentProp ? alignContent[alignContentProp] : '',
        displayGrid ? justifyContentVariants[justifyContent] : '',
        displayGrid && justifyItems ? justifyItemsVariants[justifyItems] : '',
        {
          [adaptivePaddings]: adaptiveHPaddings,
          [grid]: displayGrid,
          [block]: display === 'block',
        },
        className
      )}
    >
      {children}
    </div>
  );
};
